@import "src/app/variables.sass";

.slider-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    .slider {
        margin-right: 10px;
        padding: 75px 20px 20px;
        border-radius: 10px;
        .MuiSlider-rail, .MuiSlider-track, .MuiSlider-mark {
            height: 15px; }
        .MuiSlider-markActive {
            background-color: transparent; }
        .MuiSlider-thumb {
            height: 35px;
            width: 35px;
            margin-top: -11px; }
        .MuiSlider-valueLabel {
            left: calc(-50% + 5.5px);
            transform: scale(1) translateY(-10px)!important; }
        .PrivateValueLabel-circle-4, .jss4 {
            width: 60px;
            height: 60px;
            @include nunito;
            font-size: 14px; }
        .PrivateValueLabel-offset-3, .jss3 {
            top: -65px; }
        .MuiSlider-root {
            color: #ffd700; } } }
