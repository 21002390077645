@import "src/app/variables.sass";

.item-package {
    box-shadow: 0 8px 14px 2px rgba(0,0,0,.1);
    padding: 30px;
    background-color: white;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    border-radius: 10px;
    .likes-count {
        width: 130px;
        height: 130px;
        border: 5px solid lighten($current, 35%);
        border-radius: 50%;
        @include center;
        flex-direction: column;
        box-shadow: 0 8px 14px 2px rgba(0,0,0,.1);
        background-color: white;
        margin-bottom: 30px;
        .count {
            font-size: 32px;
            font-weight: bold; } }
    .desc {
        width: 100%;
        margin-bottom: 30px;
        div {
            position: relative;
            font-weight: lighter;
            text-align: left;
            &:before {
                content: "ー";
                margin-right: 10px;
                color: $current; } } }
    .price {
        margin-bottom: 15px;
        font-size: 32px; }
    .button {}


    &.premium {
        .likes-count {
            border: 5px solid #ffd700; }
        .button {
            background-color: #ffd700; } } }
