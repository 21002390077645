.troglodyte-page {
    padding-bottom: 50px;
    width: 100%;
    overflow-x: hidden;
    .background {
        height: 80vh;
        overflow: hidden;
        background-image: url("background.jpeg");
        background-repeat: no-repeat;
        background-size: cover;
        img {
            object-fit: cover; } }
    .content-wrap {
        width: 100%;
        .content {
            font-family: sans-serif;
            margin: auto;
            padding: 20px 10px;
            width: 900px;
            max-width: 100%;
            font-size: 16px;
            line-height: 1.4;
            h3 {
                margin-bottom: 30px; }
            blockquote {
                background: #fdfbef;
                border-left: 1px solid #bbb;
                font-style: italic;
                margin: 1.5em 10px;
                padding: 0.5em 10px;
                font-style: italic;
                padding: 30px 40px;
                font-family: Georgia; }
            p {
                margin: 20px 0px;
                text-indent: 1.5em; }
            button {
                text-align: center;
                font-size: 13px;
                text-decoration: none;
                font-weight: 700;
                padding: 3px 6px;
                background: #eaeef1;
                display: inline-block;
                width: 150px;
                cursor: pointer;
                margin: 20px;
                background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.1));
                border-radius: 3px;
                color: rgba(0,0,0,.6);
                text-shadow: 0 1px 1px rgba(255,255,255,.7);
                box-shadow: 0 0 0 1px rgba(0,0,0,.2), 0 1px 2px rgba(0,0,0,.2), inset 0 1px 2px rgba(255,255,255,.7);
                &:hover {
                    background: #fff; }
                &:active {
                    background: #d0d3d6;
                    background-image: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,0));
                    box-shadow: inset 0 0 2px rgba(0,0,0,.2), inset 0 2px 5px rgba(0,0,0,.2), 0 1px rgba(255,255,255,.2); } } } } }

.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .gif1, .gif2, .gif3, .gif4 {
        position: absolute;
        width: 150px;
        height: 150px;
        object-fit: contain; }
    .gif1 {
        top: 20px;
        left: 20px; }
    .gif2 {
        top: 20px;
        right: 50px;
        width: 250px; }
    .gif3 {
        bottom: 20px;
        left: 50px; }
    .gif4 {
        bottom: 20px;
        right: 50px; }
    .modal {
        background-color: white;
        max-width: 90%;
        position: relative;
        z-index: 10;
        h3 {
            padding: 10px 5px; }
        .img-wrap {
            img {
                max-width: 100%; } }
        .likes-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            .likes {
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    margin: 5px;
                    border-radius: 50%; } } } } }
