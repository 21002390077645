@import "/variables";

* {
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    margin: 0; }

html, body {
    overflow-x: hidden; }

.wrapper {
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    background-color: #fafafa;
    color: $font-color;
    justify-content: center;
    min-height: 100vh;
    @include lato;
    .wrap {
        width: 1600px;
        margin: 0px auto;
        padding: 15px;
        @media screen and (max-width: 1600px) {
            width: 1200px; }
        @media screen and (max-width: 1200px) {
            width: 900px; }
        @media screen and (max-width: 900px) {
            width: 100%; } } }

.h3 {
    font-size: 26px;
    @include nunito;
    margin-bottom: 10px;
    &.inactive {
        opacity: 0.7; } }
h1 {
    @include nunito;
    font-size: 60px;
    margin-bottom: 10px;
    @include rmax(900) {
        font-size: 35px; } }

.input {
    padding: 5px 15px;
    border: 1px solid $lightgray;
    border-radius: 7px;
    background-color: transparent;
    height: 36px;
    width: 100%;
    @include tr;
    @include nunito;
    font-size: 16px;
    color: $font-color;
    &:focus {
        border: 1px solid $current;
        &::placeholder {
            opacity: 0.2; } }
    &::placeholder {
        @include nunito;
        @include tr;
        line-height: 1;
        opacity: 0.7;
        font-size: 16px; } }

.hr {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    margin: 30px 0px; }

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }

img {
    width: 100%; }
.section {
    padding: 50px 10px;
    .section-title {
        text-align: center;
        padding: 0px 15px;
        @include nunito;
        font-size: 22px;
        text-transform: uppercase;
        position: relative; } }

.heart {
    width: 16px;
    height: 16px;
    margin: 0px 5px; }


