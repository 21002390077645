@import "src/app/variables.sass";

.button-container {
    width: 100%;
    @include center; }
.button {
    border-radius: 7px;
    background-color: $current;
    @include center;
    padding: 10px 15px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    height: 45px;
    min-width: 250px;
    border: 0px;
    cursor: pointer;
    margin: 15px 0px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    opacity: 0.7;
    position: relative;
    @include tr(0.15);
    &:hover {
        opacity: 1; }
    img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px; }
    .button-value {
        @include tr(0.15); }
    .spinner-container {
        display: none; }
    &.loader {
        width: 45px;
        min-width: 45px;
        margin: 15px auto;
        opacity: 1;
        cursor: auto;
        border-radius: 50%;
        .button-value {
            display: none; }
        .spinner-container {
            display: flex;
            background-color: transparent; }
        .spinner {
            height: 25px;
            width: 25px;
            border-top: 3px solid rgba(255, 255, 255, 0.5); }
        &:hover {
            opacity: 1; } } }
