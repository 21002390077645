@import "src/app/variables.sass";

.spinner-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255,255,255, 0.8);
    z-index: 10;
    @include center;
    .spinner {
        width: 70px;
        height: 70px;
        border-top: 3px solid rgba($current, .5);
        border-right: 3px solid transparent;
        border-radius: 50%;
        animation: rotation .6s linear infinite; } }

@keyframes rotation {
    from {
        transform: rotate(0deg); }
    to {
        transform: rotate(360deg); } }

