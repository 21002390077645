@import "src/app/variables.sass";

.home-page {
    width: 100%;
    .hr {
        margin: 0;
        &.hr-home {
            transform: rotate(2deg);
            box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.05); } }
    .init-section {
        width: 100%;
        height: 105vh;
        background: linear-gradient(to bottom, #0ca1d7, #42eef3);
        @include center;
        font-size: 60px;
        color: white;
        font-weight: bold;
        @include nunito;
        clip-path: polygon(0 0, 100% 0, 100% 89%, 0 100%);
        h1 {
            text-align: center;
            padding: 10px 15px; } }
    .about-section {
        .about-container {
            display: grid;
            grid-template-columns: 1fr 1.5fr;
            grid-gap: 20px;
            max-width: 1000px;
            margin: 40px auto;
            @include rmax(700) {
                grid-template-columns: 1fr; }
            .about-content {
                display: flex;
                padding: 0px 0px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .title {
                    margin-bottom: 30px;
                    width: 100%;
                    font-size: 40px; }
                .text {
                    font-size: 18px;
                    line-height: 1.5;
                    margin-bottom: 30px; }
                .button {
                    width: 100%; } }
            .about-image {
                display: flex;
                justify-content: center;
                opacity: 0.2;
                padding: 0px;
                @include rmax(900) {
                    padding: 0; }
                .img {} } } }

    .package-section {
        .package-container {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap; } }
    .premium-section {
        background: linear-gradient(to bottom, #0ca1d7, #42eef3);
        clip-path: polygon(0 0, 100% 6%, 100% 100%, 0 100%);
        padding-top: 100px;
        padding-bottom: 100px;
        .slider-container {
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 320px;
            grid-gap: 20px;
            max-width: 1000px;
            @include rmax(700) {
                grid-template-columns: 1fr; } }
        .section-title {
            color: white; }
        .slider-column {
            height: 100%;
            background-color: white;
            box-shadow: 0 8px 14px 2px rgba(0, 0, 0, 0.1);
            padding: 30px;
            border-radius: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            @include rmax(700) {
                max-width: 320px;
                margin: 0px auto;
                grid-row: 2;
                .text {
                    display: none; } }
            .title {
                font-size: 28px;
                color: #fdd700;
                letter-spacing: 2px;
                display: flex;
                align-items: center;
                img {
                    margin: 0px 10px;
                    width: 40px; } }
            .text {
                line-height: 1.6;
                opacity: 0.8; } }
        .package-column {
            margin: 0px auto;
            @include rmax(700) {
                grid-row: 1; }
            .item-package {
                margin: 0; } } } }



















