@import "src/app/variables.sass";

header {
    width: 100vw;
    height: 65px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(12,161,215,0.8);
    z-index: 100;
    .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .logo {
        @include nunito;
        font-size: 24px;
        color: white;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        img {
            width: 35px;
            margin: 0px 10px; } }
    nav {
        display: flex;
        @include rmax(700) {
            display: none; }
        .item {
            color: white;
            font-size: 18px;
            padding: 5px 10px;
            margin: 0px 5px;
            cursor: pointer;
            letter-spacing: 1px;
            &:hover {
                text-decoration: underline; } } } }
