@import "src/app/variables.sass";

.add-likes-settings {
    height: 100%;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
    @include center;
    flex-direction: column;
    justify-content: flex-start;
    .add-container {
        position: relative;
        border-radius: 5px;
        width: 700px;
        box-shadow: 0 8px 14px 2px rgba(0,0,0,.1);
        padding: 50px 40px;
        margin-bottom: 40px;
        @include rmax(700) {
            width: 90%;
            padding: 30px 10px; }
        .select-account {
            font-size: 18px;
            margin-bottom: 10px; }
        .input-wrapper {
            margin: 20px 0px; } }
    .profile-section {
        .full-name {
            margin: 3px 0px;
            font-size: 12px;
            font-weight: normal; }
        .media-box {
            margin-top: 10px;
            @include rmax(700) {
                text-align: center; }
            .media {
                margin: 3px 0;
                font-size: 14px;
                .media-value {
                    font-weight: bold; } } }
        .row.package {
            .title {
                font-size: 16px;
                white-space: nowrap;
                margin: 0px 10px 0px 0px; } }
        .profile {
            @include rmax(700) {
                flex-direction: column; } }
        .profile-info {
            display: flex;
            align-items: center;
            .name {
                font-size: 16px;
                margin: 0px 10px; }
            .img {
                width: 70px;
                height: 70px;
                @include center;
                border-radius: 100%;
                background-color: $lightgray;
                overflow: hidden;
                img {
                    object-fit: contain; } } }

        .gallery {
            display: grid;
            grid-gap: 20px 15px;
            margin-top: 20px;
            margin-bottom: 30px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            @include rmax(700) {
                grid-template-columns: 1fr 1fr 1fr; }
            @include rmax(450) {
                grid-template-columns: 1fr 1fr; }
            .gallery-item {
                position: relative;
                cursor: pointer;
                box-shadow: 0 2px 8px 1px rgba(0,0,0,.2);
                border-radius: 5px;
                overflow: hidden;
                &:before {
                    content: "+";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: white;
                    font-size: 30px;
                    background-color: rgba(0,0,0,.4);
                    z-index: 1;
                    @include center;
                    opacity: 0; }
                &:hover {
                    &:before {
                        opacity: 1; } }
                &.checked {
                    &:before {
                        display: none!important; } }
                .desc {
                    padding: 5px 5px;
                    font-size: 14px;
                    display: flex;
                    align-items: center; }
                .checked-photo {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    color: white;
                    font-size: 20px;
                    @include center;
                    background-color: rgba(0,0,0,.5);
                    z-index: 20; } }
            .img {
                overflow: hidden;
                cursor: pointer;
                img {
                    object-fit: contain; } } } }
    .payment-section {
        .total {
            font-size: 18px;
            .count {
                font-weight: bold;
                color: $current;
                font-size: 24px; } }
        .payment-checked-container {
            margin-bottom: 30px;
            margin-top: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px 15px;
            @include rmax(340) {
                grid-template-columns: 1fr; }
            .item {
                padding: 5px 10px;
                border-radius: 6px;
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
                box-shadow: {}
                .img-box {
                    display: flex;
                    align-items: center; }
                .img {
                    height: 40px;
                    width: 40px;
                    border-radius: 6px;
                    margin-right: 10px; }
                .likes {
                    font-weight: bold; }
                .close {
                    cursor: pointer;
                    padding: 5px 10px;
                    &:hover {
                        opacity: 0.7; } } } } } }
