.error-block {
    border: 1px solid #D70000;
    background-color: rgba(159, 0, 0, .1);
    padding: 10px 15px;
    opacity: 0.6;
    border-radius: 5px;
    color: #d70000;
    .error-title {
        font-weight: bold;
        margin-bottom: 5px; }
    .error-text {
        font-size: 14px; } }
