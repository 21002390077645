$current: #0ca1d7;
$lightgray: #e0e0e0;
$font-color: #636363;

@mixin lato {
    font-family: Lato,Helvetica,Sans-serif;
    font-weight: 400; }

@mixin nunito {
    font-family: Nunito,Helvetica,Sans-serif;
    font-weight: 800; }

@mixin center {
    display: flex;
    justify-content: center;
    align-items: center; }

@mixin rmax($width) {
    @media only screen and (max-width: $width+ "px") {
        @content; } }

@mixin rmin($width) {
    @media only screen and (min-width: $width+ "px") {
        @content; } }

@mixin tr($tr:0.35) {
     transition: $tr+s all ease; }

@mixin fz($d320, $d760: $d320, $d1200: $d760, $d1900: $d1200) {
    font-size: $d320+px;
    @include rmin(760) {
        font-size: $d760+px; }
    @include rmin(1200) {
        font-size: $d1200+px; }
    @include rmin(1900) {
        font-size: $d1900+px; } }
